import React from "react";
import { withMsal, WithMsalProps } from "@azure/msal-react";
import App from "./App";

class AuthenticatedAzure extends React.Component<WithMsalProps> {
  state = { accessToken: "" };
  callLogin() {
    const msalInstance = this.props.msalContext.instance;

    const account = msalInstance.getAllAccounts()[0];
    msalInstance
      .acquireTokenSilent({ scopes: ["openid", "email"], account })
      .then((a: any) => {
        this.setState({ accessToken: a.accessToken });
      });
  }

  componentDidMount() {
    this.callLogin();
  }

  componentDidUpdate() {
    this.callLogin();
  }

  render() {
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (isAuthenticated && this.state.accessToken.length > 0) {
      return (
        <App
          azureAccessToken={this.state.accessToken}
          loginType={"chain"}
        ></App>
      );
    } else {
      return <span>Authentication in progress</span>;
    }
  }
}

const WrappedAuthenticatedAzure = withMsal(AuthenticatedAzure);

export default WrappedAuthenticatedAzure;
