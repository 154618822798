import React from "react";
import { withMsal, WithMsalProps } from "@azure/msal-react";
import App from "./App";

class Azure extends React.Component<WithMsalProps> {
  state = { accessToken: "", idToken: "" };

  callLogin() {
    // const isAuthenticated = this.props.msalContext.accounts.length > 0;
    const msalInstance = this.props.msalContext.instance;
    msalInstance.handleRedirectPromise().then((tokenResponse: any) => {
      if (!!tokenResponse) {
        this.setFinalDestination(tokenResponse.account?.username);
        this.setState({
          accessToken: tokenResponse.accessToken,
          idToken: tokenResponse.idToken,
        });
      }
    });

    // If a user is not logged in and authentication is not already in progress, invoke login
    msalInstance.loginRedirect({
      prompt: "select_account",
      scopes: ["openid", "email"],
    });
  }

  private setFinalDestination(username: string | undefined) {
    const search = window.location.search;
    const incomingParams = new URLSearchParams(search);
    const redirectUri = incomingParams.get("redirect_uri") as string;
    if (username) {
      sessionStorage.setItem(
        "redirect_uri",
        redirectUri + "?login_hint=" + username
      );
    } else {
      sessionStorage.setItem("redirect_uri", redirectUri);
    }
  }

  componentDidMount() {
    this.callLogin();
  }

  componentDidUpdate() {}

  render() {
    if (this.state.accessToken && this.state.idToken) {
      return (
        <App
          azureAccessToken={this.state.accessToken}
          idToken={this.state.accessToken}
          loginType={"chain"}
        ></App>
      );
    } else {
      return <span></span>;
    }
  }
}

const WrappedAzure = withMsal(Azure);
export default WrappedAzure;
