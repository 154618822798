import "./App.css";
import React from "react";
import logo from "./smfa.jpg";
import { HaventecMfaClient } from "@haventec/mfa-client";

class Login extends React.Component {
  client: HaventecMfaClient;
  state = {
    username: "",
    statusMessage: "",
    result: undefined,
    activationStatus: ""
  };
  constructor(props: {
    azureAccessToken: string;
    idToken: string;
    loginType: "chain";
  }) {
    super(props);

    let baseUrlBackend: string = "/api/smfaproxy/silent-mfa/v1-0";

    this.client = new HaventecMfaClient({
      pollTimeInMs: 5000,
      checkUrl:  baseUrlBackend + "/direct/check",
      registerUrl:  baseUrlBackend + "/direct/register",
      loginUrl:  baseUrlBackend +"/direct/login",
      restartPollOnLogin: false,
    });

  }


  private registerCallback = (response: any): void => {
    this.setState({
      ...this.state,
      activationStatus: response.firstDeviceVerified
        ? "authenticating"
        : "onboarding",
    });
  };

  submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing the page from reloading
    event.preventDefault();
    const search = window.location.search;
    const incomingParams = new URLSearchParams(search);
    const clientId = incomingParams.get("client_id") as string;
    if (!this.state.username) {
      return;
    }
    // Do something
    this.client
      .loginWithRegisterFallback(
        {
          username: this.state.username,
        },
        clientId,
        this.registerCallback
      )
      .then(() => {
        this.setState({
          ...this.state,
          result: window.sessionStorage.getItem(this.state.username),
          activationStatus: "activated",
          statusMessage: "OK",
        });
      })
      .catch((err) => {
        var errorCode = err.errorCode;
        var errorMessage = err.response;

        switch (errorMessage) {
          case "Network Error":
            errorMessage +=
              ": Please check that the server is started and try again.";
            break;
          case "Request failed with status code 500":
            errorMessage += ": Please contact system administrator.";
            break;
          default:
            break;
        }

        if (errorCode === "AN-JSON-1004") {
          errorMessage = "Username must be a valid email address.";
        }
        this.setState({
          ...this.state,
          activationStatus: "failure",
          statusMessage: errorMessage,
        });
      });
  };

  render() {
    return (
      <div className="container">
        {this.state.activationStatus !== "authenticating" && (
          <div className="image-content">
            <img src={logo} alt="logo" />
          </div>
        )}
        <div className="text-content">
          {this.state.activationStatus === "" && (
            <form onSubmit={this.submitForm}>
              <input
                value={this.state.username}
                onChange={(e) => this.setTerm(e.target.value)}
                type="text"
                placeholder="User Email*"
                className="user-input"
              />
              <br />
              <br />
              <input
                type="password"
                placeholder="Password"
                className="user-input"
              />
              <br />
              <br />
              <button type="submit" className="submit-button">
                Submit
              </button>
              <br />
              <br />
            </form>
          )}
          {this.state.activationStatus === "onboarding" && (
            <div>
              <span className="info highlighted emphasis">
                Please do not close this page until you have successfully
                completed the one-time activation using the magic link sent to
                your email.
              </span>
              <div className="onboarding">
                <h1>
                  We care about protecting your accounts and personal
                  information.
                </h1>
                <div className="action">
                  <span>
                    We have sent you an email about how to complete the one-time
                    activation process for Silent MFA on this device.
                  </span>
                  <span>
                    Once your device is activated, every time you login with
                    your username and password we will complete a Multi-factor
                    Authentication to enhance the protection of your accounts.
                  </span>
                </div>
                <div className="smfa-container">
                  <span className="smfa-info">
                    Multi-factor Authentication, or MFA, is designed to make
                    sure that you are the only person who can access your
                    account. MFA is one of the most effective ways of protecting
                    against unauthorised access to your accounts and personal
                    information.
                  </span>
                </div>
              </div>
            </div>
          )}
          {!!this.state.result && (
            <div className="smfa-container">
              <span className="smfa-info">
                <b>{this.state.username}</b> logged in
              </span>
            </div>
          )}
          {this.state.activationStatus === "failure" && (
            <div className="failure emphasis highlighted">
              {this.state.statusMessage}
            </div>
          )}
        </div>
      </div>
    );
  }

  private setTerm(value: string) {
    this.setState({ username: value });
  }
}

export default Login;
