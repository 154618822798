import "./App.css";
import React from "react";
import { HaventecMfaClient } from "@haventec/mfa-client";
import logo from "./smfa.jpg";
import jwtDecode from "jwt-decode";
import {AzureAccessToken} from "@haventec/mfa-client/dist/types/lib/register/register-service";

class App extends React.Component<{
  azureAccessToken?: string;
  idToken?: string;
  loginType?: "chain";
}> {
  client: HaventecMfaClient;
  state = { statusMessage: "", activationStatus: "", timer: -1 };
  code: string | null;
  username: string | null;
  incomingParams: URLSearchParams;
  registrationReturned = false;
  loginType: "chain" | null;
  azureAccessToken: string | null;
  idToken: string | null;
  clientId: string;

  constructor(props: {
    azureAccessToken: string;
    idToken: string;
    loginType: "chain";
  }) {
    super(props);
    this.loginType = props.loginType;
    this.azureAccessToken = props.azureAccessToken;
    this.idToken = props.idToken;
    this.clientId = window.location.host.split(".")[0];

    let baseUrlBackend: string = "/api/smfaproxy/silent-mfa/v1-0";

    console.log("OIDC",baseUrlBackend);
    this.client = new HaventecMfaClient({
      pollTimeInMs: 5000,
      checkUrl:
          baseUrlBackend + "/activate",
      registerUrl: baseUrlBackend + "/register",
      loginUrl: baseUrlBackend + "/login",
      idpConfig: {
        idpRegisterUrl:
            baseUrlBackend +
            "/" + this.clientId + "/register/idp",
      },
      //TODO proxy this and secure authenticate endpoints
      chainConfig: {
        loginUrl: baseUrlBackend + "/chain/login",
        registerUrl: baseUrlBackend + "/chain/register",
      },
      restartPollOnLogin: true,
    });

    /**
     * Save MFA IDP State if required,
     */
    const search = window.location.search;
    this.incomingParams = new URLSearchParams(search);
    this.code = this.incomingParams.get("code");
    this.username = this.incomingParams.get("username");
  }

  componentDidMount() {
    /**
     * Determine if logging in or registering
     * activationStatus will be:
     * 'onboarding' when no username
     * 'onboarding' when username but no authKey
     * 'authenticating' when username and authkey
     */

    let activationStatus = "authenticating";

    this.setState({
      ...this.state,
      activationStatus: activationStatus,
    });

    if (this.state.statusMessage === "") {
      this.setup()
        .then((status) => {
          this.registrationReturned = true;
          let statusMessage;
          switch (status) {
            case "authenticating":
              activationStatus = "authenticating";
              break;
            case "failure":
              statusMessage =
                "There has been an error. Please try logging in again.";
              activationStatus = "failure";
              break;
            case "error":
              statusMessage =
                "Error: No 'code' and/or 'username' found. Please try logging in again.";
              activationStatus = "failure";
              break;
            default:
              break;
          }
          this.setState({
            ...this.state,
            activationStatus: activationStatus,
            statusMessage: statusMessage,
          });
          return status;
        })
        .then((status) => {
          if (status === "authenticating" && this.loginType === "chain") {
            this.countDownToRedirect(5);
          }
        });
    }
  }

  private countDownToRedirect(timer:number) {
    setTimeout(() => {
      if (timer === 0) {
        window.open(sessionStorage.getItem("redirect_uri") as string, "_self");
      } else {
        this.setState({
          ...this.state,
          timer: timer - 1,
        });
        this.countDownToRedirect(timer - 1);
      }
    }, 1000);
  }

  private registerCallback = (response: any): void => {
    this.setState({
      ...this.state,
      activationStatus: response.firstDeviceVerified
        ? "authenticating"
        : "onboarding",
    });
  };

  setup(): Promise<string> {
    if (this.loginType === "chain") {
      const search = window.location.search;
      this.incomingParams = new URLSearchParams(search);
      if (this.azureAccessToken != null && this.idToken != null) {
        return this.client
          .azureLogin(
            this.azureAccessToken,
            this.idToken,
            this.clientId,
            undefined,
            this.registerCallback
          )
          .then(() => {
            return "authenticating";
          })
          .catch(() => {
            const username = jwtDecode<AzureAccessToken>(
              this.azureAccessToken as string
            );
            this.client.authStore.clearLoginPayload(username.oid);
            return "failure";
          });
      } else {
        return Promise.resolve("error");
      }
    } else {
    }

    if (this.code && this.username) {
      return this.client
        .loginWithRegisterFallbackIdpCode(this.registerCallback)
        .then(() => {
          return "authenticating";
        })
        .catch(() => {
          return "failure";
        });
    } else {
      return Promise.resolve("error");
    }
  }

  render() {
    return (
      <div className="container">
        <div className="image-content">
          <img src={logo} alt="logo" />
        </div>
        <div className="text-content">
          {this.state.activationStatus === "onboarding" && (
            <div>
              <span className="info highlighted emphasis">
                Please do not close this page until you have successfully
                completed the one-time activation using the magic link sent to
                your email.
              </span>
              <div className="onboarding">
                <h1>
                  We care about protecting your accounts and personal
                  information.
                </h1>
                <div className="action">
                  <span>
                    We have sent you an email about how to complete the one-time
                    activation process for Silent MFA on this device.
                  </span>
                  <span>
                    Once your device is activated, every time you login with
                    your username and password we will complete a Multi-factor
                    Authentication to enhance the protection of your accounts.
                  </span>
                </div>
                <div className="smfa-container">
                  <span className="smfa-info">
                    Multi-factor Authentication, or MFA, is designed to make
                    sure that you are the only person who can access your
                    account. MFA is one of the most effective ways of protecting
                    against unauthorised access to your accounts and personal
                    information.
                  </span>
                </div>
              </div>
            </div>
          )}
          {this.state.activationStatus === "authenticating" && (
            <div className="authenticating">
              <h1>
                We care about protecting your accounts and personal information.
              </h1>
              <span>Silent MFA automatically protects your account.</span>
            </div>
          )}
          {this.loginType === "chain" &&
            this.state.timer !== -1 && (
              <span>Login Success. Redirecting you in {this.state.timer} seconds</span>
            )}
          {this.state.activationStatus === "failure" && (
            <div className="failure emphasis highlighted">
              {this.state.statusMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
