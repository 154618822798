import React from "react";

class Redirect extends React.Component {
  state = { message: "" };

  componentDidMount() {
    const search = window.location.search;
    let incomingParams = new URLSearchParams(search);

    /**
     * Save MFA IDP State if required,
     */

    const code = incomingParams.get("code");
    if (code) {
      window.sessionStorage.setItem("code", code);

      //TODO config destination and redirectURl
      window.location.assign(
        "http://localhost:8080/realms/silent-mfa/protocol/openid-connect/auth?client_id=test-client" +
          "&redirect_uri=https://office.com&response_mode=fragment&response_type=code&scope=openid" +
          "&kc_idp_hint=mfa"
      );
    } else {
      this.setState({ message: "Unable to get code from login" });
    }
  }
  render() {
    return (
      <div className="container">
        <div>{this.state.message}</div>
      </div>
    );
  }
}
export default Redirect;
