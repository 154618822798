import React from "react";

class Authorize extends React.Component {
  state = { message: "" };

  componentDidMount() {
    const search = window.location.search;
    let incomingParams = new URLSearchParams(search);

    const code = window.sessionStorage.getItem("code");
    if (code) {
      /**
       * Save MFA IDP State if required,
       */

      const authorize =
        "http://localhost:9000/authenticate/v1-2/oidc/chain/authorize?" +
        incomingParams.toString() +
        "&code=" +
        code;

      //https://<host>/auth/realms/<realm>/protocol/openid-connect/logout?redirect_uri=https://<host>/auth/realms/<realm>/protocol/openid-connect/registrations?client_id=account&response_type=code&scope=openid+email&redirect_uri=<url_to_account_app>.
      //TODO config url to backend authorize endpoint
      //  window.open("http://localhost:8080/realms/silent-mfa/protocol/openid-connect/logout?client_id=test-client&post_logout_redirect_uri=" + authorize, "_self");
      window.open(authorize, "_self");
    } else {
      this.setState({ message: "Unable to get code from login" });
    }
  }
  render() {
    return (
      <div className="container">
        <div>{this.state.message}</div>
      </div>
    );
  }
}
export default Authorize;
