import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Login from "./Login";
import WrappedApp from "./App";
import Redirect from "./Redirect";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authorize from "./Authorize";
import WrappedAuthenticatedAzure from "./AuthenticatedAzure";
import { PublicClientApplication } from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser/dist/config/Configuration";
import WrappedAzure from "./Azure";
import { MsalProvider } from "@azure/msal-react";

const search = window.location.search;
const incomingParams = new URLSearchParams(search);
const azureClientId = incomingParams.get("azure_client_id") as string;
const tenantId = incomingParams.get("tenant_id") as string;
const authority = ("https://login.microsoftonline.com/" +
  incomingParams.get("tenant_id")) as string;
const domain = window.location.host.split("/azure")[0];

const msalConfig: Configuration = {
  auth: {
    clientId: azureClientId,
    authority: authority,
    redirectUri:
    window.location.protocol + "//"+
    domain +
      "/azure?azure_client_id=" +
      azureClientId +
      "&tenant_id=" +
      tenantId,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <div>
        <Routes>
          <Route path="/" element={<WrappedApp />} />
          <Route path="/redirect" element={<Redirect />} />
          <Route path="/authorize" element={<Authorize />} />
          <Route path="/azure" element={<WrappedAzure />} />
          <Route
            path="/azure/authenticated"
            element={<WrappedAuthenticatedAzure />}
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </MsalProvider>
  </BrowserRouter>
);
